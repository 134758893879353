@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,300&display=swap');

// colors
$primaryColor: (
    "full-color": rgb(51, 102, 102),
    "opacity-80": rgba(51, 102, 102, 0.8),
    "opacity-50": rgba(51, 102, 102, 0.5),
    "opacity-30": rgba(51, 102, 102, 0.3),
    "opacity-10": rgba(51, 102, 102, 0.1)
);
$menuColor: #8ea3a3;
$defaultWhite: #fff;
$defaultDark: #676766;
$defaultGrey: #999999;
$defaultBlack: #000000;
$backgroundGrey: #EDEDED;

@function colors($colors-name) {
  @return map-get($primaryColor , $colors-name )
}

// fonts
$defaultFontFamily: 'Roboto', Arial, sans-serif;;

$desktopBreakpoint: 950px;

//svg
$svgSize: 2rem;



