@import 'variables.scss';
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  background-color: $backgroundGrey;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

iframe{
  height: 43em;
  width: 25em;
  // padding: 20px;
}

.App {
  text-align: center;
  font-family: $defaultFontFamily;

  h1 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: 700;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 700;
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
  }

  .small-link-text {
    font-size: 1.2rem;
    font-weight: 400;
    color: $defaultDark;
  }

  a {
    text-decoration: none;
  }

  .content-wrapper {
    font-size: 160%;

    h1 {
      font-size: 2.4rem;
    }
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.6rem;
    }

  }

  .content-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 7.5rem;
    padding-left: 2rem;
    background: colors(full-color);

    h1 {
      font-size: 1.4rem;
      color:$defaultWhite;
      margin-left: 2rem;
    }

    svg {
      font-size: 2rem;
      stroke: $defaultWhite;
      fill: $defaultWhite;
      color: white;
    }
  }
}

.content-container {
  width: 100%;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.create-new-button {
  min-height: 7.5rem;
  cursor: pointer;
}

.searchbar-container {
  display: inline-block;
  min-width: 28rem;
  position: relative;
}

.searchbar-icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: .5rem;
}

.searchbar {
  height: 4rem;
  width: 100%;
  background-color: $defaultWhite;
  color: $defaultGrey;
  border: 0.1rem solid $defaultGrey;
  outline: none;
  text-align: left;
  padding-right: 3rem;
}

.MuiTooltip-popper {
  font-size: 2rem;
}

.MuiTooltip-tooltip {
  font-size: 2rem;
}

// Prevent zoom in for inputs
body {
  [class^="Mui"][class*="-root"], .MuiTooltip-tooltip, [class^="MuiInputBase"] {
    font-size: 1.2rem !important;
  }

  &.user-has-touch{
    [class^="Mui"][class*="-root"], .MuiTooltip-tooltip, [class^="MuiInputBase"] {
      font-size: 1.6rem !important;
    }
  }
}


.MuiSvgIcon-root {
  width: 3rem !important;
  height: 3rem !important;
}

@media screen and (min-width: $desktopBreakpoint) {
  .content-wrapper {
    margin-left: 29.4rem;
  }
  .content-wrapper.no-menu {
    margin-left: 0;
  }
  .content-container {
    max-width: 90rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
