@import '../../variables.scss';

.header {
  background-color: colors(full-color);
  width: 100%;
  height: 30vh;
  margin: 0 0 1.6rem 0;
  color: $defaultWhite;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 15vh;
  }

  .header-title {
    text-transform: uppercase;
    font-size: 3rem;
    width: 90vw;

    @media screen and (min-width: $desktopBreakpoint) {
      width: 100%;
    }
  }
}

